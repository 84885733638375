<template>
	<main v-if="visible" class="layer-popup-container">
		<div class="layer-popup" id="layerPopupTerms">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<h2 class="header-title">개인정보 수집 이용 및 제공 동의(당첨)</h2>
						<button type="button" class="btn-close" @click="clickClose">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body">
						<p class="popup-body-desc mb1">2024년 9월 동행제품 이벤트 당첨 경품 지급을 위하여 「개인정보 보호법」에 따라 본인의 동의를 얻어야 합니다. 아래의 개인정보 및 고유식별정보 수집·이용·제공에 관한 내용을 자세히 읽어 보신 후 동의 여부를 결정하여 주시기 바랍니다.</p>
						<div class="form-checkbox is-round is-largetext">
							<input type="checkbox" v-model="allAgree" id="check11" @change="allAgreeClick" />
							<label for="check11">모두 동의</label>
						</div>
						<!-- s: 고유식별정보 수집·이용 -->
						<section class="section">
							<div class="section-content">
								<div class="terms-wrap">
									<section class="terms-section">
										<h4 class="terms-section-title">1. (필수) 동행축제 이벤트 경품 지급을 위한 고유식별정보 수집·이용에 관한 사항(5만 원 초과 경품인 경우)</h4>
										<ul class="dot-list">
											<li>수집·이용 목적 : 동행축제 이벤트 경품 지급, 본인확인, 문의, 안내사항 전달, 원천징수 및 소득신고 등</li>
											<li>수집·이용 항목 : 주민등록번호(또는 외국인등록번호), 신분증 사본(앞면)</li>
											<li>보유·이용 기간 : 수집일로부터 3개월 (경품지급 완료 후 파기) / 단, 관련 법령의 별도 규정이 명시되어 있는 경우 그 기간을 따름</li>
										</ul>

										<p>※ 동의를 거부할 권리 및 동의를 거부할 경우의 불이익 : 위 고유식별정보의 수집·이용에 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 2024년 9월 동행축제 이벤트 경품지급이 불가합니다.</p>
									</section>
								</div>
							</div>
							<div class="section-header">
								<h4 class="title">
									<div class="form-checkbox is-round">
										<input type="checkbox" v-model="agree1" id="check12" @change="agreeClick" />
										<label for="check12">
											<span>위와 같이 고유식별정보 수집·이용에 동의합니다</span>
										</label>
									</div>
								</h4>
							</div>
						</section>
						<!-- e: 고유식별정보 수집·이용 -->
						<!-- s: 개인정보 수집·이용 -->
						<section class="section">
							<div class="section-content">
								<div class="terms-wrap">
									<section class="terms-section">
										<h4 class="terms-section-title">2. (필수) 동행축제 이벤트 경품 지급을 위한 개인정보 수집·이용에 관한 사항</h4>
										<ul class="dot-list">
											<li>수집·이용 목적 : 동행축제 이벤트 경품 지급, 본인확인, 문의, 안내사항 전달, 원천징수 및 소득신고 등</li>
											<li>수집·이용 항목 : 이름, 연락처, 연락처 인증, 제세공과금 입금 확인을 위한 입금증, 소득신고 관련 주소 등</li>
											<li>보유·이용 기간 : 수집일로부터 3개월 (경품지급 완료 후 파기) / 단, 관련 법령의 별도 규정이 명시되어 있는 경우 그 기간을 따름</li>
										</ul>

										<p>※ 동의를 거부할 권리 및 동의를 거부할 경우의 불이익 : 위 개인정보의 수집·이용에 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 2024년 9월 동행축제 이벤트 경품지급이 불가합니다.</p>
									</section>
								</div>
							</div>
							<div class="section-header">
								<h4 class="title">
									<div class="form-checkbox is-round">
										<input type="checkbox" v-model="agree2" id="check13" @change="agreeClick" />
										<label for="check13">
											<span>위와 같이 개인정보 수집·이용에 동의합니다</span>
										</label>
									</div>
								</h4>
							</div>
						</section>
						<!-- e: 개인정보 수집·이용 -->
						<!-- s: 개인정보 제3자 제공 -->
						<section class="section">
							<div class="section-content">
								<div class="terms-wrap">
									<section class="terms-section">
										<h4 class="terms-section-title">3. (필수) 동행축제 이벤트 경품지급을 위한 개인정보 제3자 제공에 관한 사항</h4>
										<p class="mt1 mb0">① 개인정보를 제공받는 자 : 중소벤처기업부</p>
										<ul class="dot-list">
											<li>개인정보를 제공받는 자의 개인정보 이용 목적 : 동행축제 이벤트 경품 지급, 본인확인, 문의, 안내사항 전달, 조사 및 통계, 성과 관리 등</li>
											<li>제공하는 개인정보 항목 : 이름, 연락처, 연락처 인증, 제세공과금 입금 확인을 위한 입금증, 경품 수령을 위한 주소 등</li>
											<li>제공받는자의 보유·이용 기간 : 제공일로부터 3개월 (제공받는자의 목적 달성시까지) / 단, 관련 법령의 별도 규정이 명시되어 있는 경우 그 기간을 따름</li>
										</ul>
										<p class="mt1 mb0">② 개인정보를 제공받는 자 : 국세청</p>
										<ul class="dot-list">
											<li>개인정보를 제공받는 자의 개인정보 이용 목적 : 동행축제 찜하기 이벤트 경품 지급관련 원천징수 및 소득신고</li>
											<li>제공하는 개인정보 항목 : 주민등록번호(또는 외국인등록번호), 이름, 연락처, 주소 등</li>
											<li>제공받는자의 보유·이용 기간 : 관련법령에서 규정한 기간까지</li>
										</ul>
										<p>※ 동의를 거부할 권리 및 동의를 거부할 경우의 불이익 : 위 개인정보의 제3자 제공에 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 동행축제 이벤트 경품 지급이 불가합니다.</p>
									</section>
								</div>
							</div>
							<div class="section-header">
								<h4 class="title">
									<div class="form-checkbox is-round">
										<input type="checkbox" v-model="agree3" id="check14" @change="agreeClick" />
										<label for="check14">
											<span>위와 같이 개인정보 제3자 제공에 동의합니다</span>
										</label>
									</div>
								</h4>
							</div>
						</section>
						<!-- e: 개인정보 제3자 제공 -->
						<!-- s: 고유식별정보(주민등록번호, 외국인등록번호 등)의 처리에 관한 고지 -->
						<section class="section">
							<div class="section-content">
								<div class="terms-wrap">
									<section class="terms-section">
										<h4 class="terms-section-title">4.(안내) 고유식별정보(주민등록번호, 외국인등록번호 등)의 처리에 관한 고지</h4>
										<ul class="dot-list">
											<li>「개인정보 보호법」 제24조, 제24조의2 및 「국세기본법 시행령」 제68조제3항에 근거하여 귀하의 주민등록번호(또는 외국인등록번호)는 원천징수 및 소득신고 업무를 위하여 수집·이용 및 국세청에 제공됩니다.</li>
										</ul>
										<p class="mt1 mb0">※ 기타 고지사항</p>
										<ul class="dot-list">
											<li>기타 이벤트 행사 진행을 위한 개인정보처리업무 위탁현황은 홈페이지에 공개된 개인정보 처리방침에서 확인하실 수 있습니다.</li>
										</ul>
										<p class="mt1 mb1">< 개인정보처리업무 위탁 현황 ></p>
										<p>
											① 수탁업체 : ㈜대홍기획<br />
											위탁하는 업무의 내용 : 2024년 5·9월 동행축제 행사 총괄수행 대행사<br />
											보유 및 이용기간 : 2024년 5·9월 동행축제 종료시까지
										</p>
										<p>
											② 수탁업체 : ㈜씨리얼팝 / ㈜스토리잇다<br />
											위탁하는 업무의 내용 : 2024년 5·9월 동행축제 이벤트 수행 대행사<br />
											보유 및 이용기간 : 2024년 5·9월 동행축제 종료시까지
										</p>
										<p>
											③ 수탁업체 : ㈜시온리서치<br />
											위탁하는 업무의 내용 : 2024년 5·9월 동행축제 만족도 설문조사 등<br />
											보유 및 이용기간 : 2024년 5·9월 동행축제 종료시까지
										</p>
										<p>
											④ 수탁업체 : ㈜카테노이드<br />
											위탁하는 업무의 내용 : 2024년 5·9월 동행축제 홈페이지 운영유지관리<br />
											보유 및 이용기간 : 2024년 5·9월 동행축제 종료시까지
										</p>
									</section>
								</div>
							</div>
						</section>
						<!-- e: 고유식별정보(주민등록번호, 외국인등록번호 등)의 처리에 관한 고지 -->

						<ul class="dot-list mb1">
							<li>개인정보 제공자가 동의한 내용 외에 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보의 열람청구를 처리하는 부서·담당자(소비촉진총괄기획팀, 02-6678-9882)를 통해 열람·정정·삭제를 요구할 수 있습니다</li>
							<li>위와 같이 개인정보 및 고유식별정보를 수집·이용·제공·활용하는데 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우, 동행축제 이벤트 참여가 불가합니다.</li>
						</ul>
					</div>
					<div class="popup-footer">
						<div class="buttons">
							<button type="button" class="button is-medium is-secondary" @click="confirmClick">확인</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Icon from '@/components/common/Icon';
import { 
	MUT_SHOW_ALERT,
} from '@/store/_mut_consts';
export default {
	name: 'EventTermsModal',
	props: {
		visible: Boolean,
		isLoading: false,
		noShow: false,
	},
	components: { Icon },
	data: () => ({
		allAgree: false,
		agree1: false,
		agree2: false,
		agree3: false,
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		clickClose() {
			this.$emit('closeModal');
		},
		allAgreeClick() {
			if (this.allAgree) {
				this.agree1 = true;
				this.agree2 = true;
				this.agree3 = true;
			} else {
				this.agree1 = false;
				this.agree2 = false;
				this.agree3 = false;
			}
		},
		agreeClick() {
			if (this.agree1 && this.agree2 && this.agree3) {
				this.allAgree = true;
			} else {
				this.allAgree = false;
			}
		},

		confirmClick() {
			if (this.allAgree) {
				this.$emit('agreeModal');
			} else {
				 this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: `약관 동의를 체크해주세요.` });
			}
		},
		
	},
};
</script>
